import store from '@/store/index.js'
//获取浏览器
export function getBrowserInfo() {
  var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
  // console.log("loginuserAgent:", userAgent)
  //判断是否Opera浏览器
  if (userAgent.indexOf('Opera') > -1) {
    return 'Opera'
  }

  //判断是否Edge浏览器
  if (userAgent.indexOf('Edg') > -1) {
    return 'Edge'
  }

  //判断是否Firefox浏览器
  if (userAgent.indexOf('Firefox') > -1) {
    return 'firefox'
  }

  //判断是否Chrome浏览器
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome'
  }

  //判断是否Safari浏览器
  if (userAgent.indexOf('Safari') > -1) {
    return 'Safari'
  }

  //判断是否IE浏览器
  if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
    return 'IE'
  }
  if (userAgent.indexOf('Trident') > -1) {
    return 'IE'
  }
}

//判断是否有按钮权限
export function isHavePermission(permission) {
  if (store.state.permissionList.map(one => one.permissionCode).includes(permission)) {
    return true
  } else {
    return false
  }
}
